import React, { useState, useEffect } from 'react';
import './MemoryMatch.css';
import Web3 from 'web3';

import memoryMatchGameJson from './MemoryMatchGameABI.json';
import vitaeTokenJson from './VitaeTokenABI.json';

const memoryMatchGameABI = memoryMatchGameJson.abi;
const vitaeTokenABI = vitaeTokenJson.abi;

const tokenAddress = '0x3c4bc6AF04AA092cdA5c7B239ee3EfC729a5b9C8';
const contractAddress = '0x430CeB77058CD95f068b788AEFB765e1eeBf1B8c';
const playCost = Web3.utils.toWei('1', 'ether');

const MemoryMatch = () => {
  // ---------------------
  // States & Hooks
  // ---------------------
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [flipped, setFlipped] = useState([]);
  const [matched, setMatched] = useState([]);
  const [cards, setCards] = useState([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [timer, setTimer] = useState(0);
  const [timeRanOut, setTimeRanOut] = useState(false);

  // ---------------------
  // Lifecycle
  // ---------------------
  useEffect(() => {
    resetGame();
  }, []);

  useEffect(() => {
    if (timer > 0 && !gameOver) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setGameOver(true);
      setTimeRanOut(true);
    }
  }, [timer, gameOver]);

  // ---------------------
  // Wallet
  // ---------------------
  const connectToMetaMask = async () => {
    try {
      setLoading(true);
      if (!window.ethereum) {
        console.error('MetaMask not found.');
        return;
      }
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const newWeb3 = new Web3(window.ethereum);
      const userAccounts = await newWeb3.eth.getAccounts();
      setWeb3(newWeb3);
      setAccounts(userAccounts);
      console.log('Connected accounts:', userAccounts);
    } catch (error) {
      console.error('Error connecting:', error);
    } finally {
      setLoading(false);
    }
  };

  const disconnectWallet = () => {
    setWeb3(null);
    setAccounts([]);
    console.log('Disconnected wallet.');
  };

  // ---------------------
  // Game Logic
  // ---------------------
  const resetGame = () => {
    const images = [
      'https://bafybeiduq2kemefo7v7hpx25djy27yxbadi5mf4dhln6eydcm6fzb7oxp4.ipfs.nftstorage.link/',
      'https://bafybeiccyb3v6lyi5wsli267vuwuamoqtibtfs4juzxsjwh3brjvp4o7fu.ipfs.nftstorage.link/',
      'https://bafybeiagdvdm3jnrwz4axfto4w6coddxbvirc7wpqjbcbuvhir2ysdnswq.ipfs.nftstorage.link/',
      'https://bafybeihik6ondjkwb6d4yz6yyh6fe4dnsd3nroa755v2jj6tln4z5o4nhi.ipfs.nftstorage.link/',
      'https://bafybeig3y2xvygyqwozhzekt5blsvdoqj2y6rktc7hsv6xm44c5l57kpm4.ipfs.dweb.link/image.jpeg',
      'https://bafkreibegirxmtv3txhgz24peo6c6y3dq6vidmd5vubxcuomexyys7wi7e.ipfs.nftstorage.link/',
      'https://bafybeiad5jgis7ymade52pvrnzdeqglpy5dx33vuosa7z2dgb7cvrkgbmu.ipfs.nftstorage.link/',
      'https://bafkreihrjwrf5sl2sv6zxqt7z2z2enfdzniimzri3m7tbhrodfpxh6bnoq.ipfs.nftstorage.link/',
    ];
    const shuffled = [...images, ...images].sort(() => Math.random() - 0.5);
    setCards(shuffled);
    setFlipped([]);
    setMatched([]);
    setScore(0);
    setTimer(60);
    setGameOver(false);
    setTimeRanOut(false);
  };

  const handleCardClick = (index) => {
    if (flipped.length === 2 || matched.includes(index) || gameOver) return;
    setFlipped((prev) => [...prev, index]);

    if (flipped.length === 1) {
      const firstIndex = flipped[0];
      const secondIndex = index;

      if (cards[firstIndex] === cards[secondIndex]) {
        setMatched((prev) => [...prev, firstIndex, secondIndex]);
        setScore((prev) => prev + 10);
        if (matched.length + 2 === cards.length) {
          setGameOver(true);
        }
      } else {
        setScore((prev) => (prev > 0 ? prev - 5 : 0));
        setTimeout(() => setFlipped([]), 1000);
      }
      setFlipped([]);
    }
  };

  // ---------------------
  // Start Game Logic
  // ---------------------
  const startGame = async () => {
    if (!web3 || accounts.length === 0) {
      console.log('Wallet not connected. Connecting...');
      await connectToMetaMask();
    }
    if (!web3 || accounts.length === 0) {
      console.error('No wallet connected after connect attempt.');
      return;
    }

    try {
      setLoading(true);
      const tokenContract = new web3.eth.Contract(vitaeTokenABI, tokenAddress);
      const gameContract = new web3.eth.Contract(memoryMatchGameABI, contractAddress);

      console.log('Approving 1 VTAE...');
      await tokenContract.methods
        .approve(contractAddress, playCost)
        .send({ from: accounts[0] });

      console.log('Calling playGame()...');
      await gameContract.methods
        .playGame()
        .send({ from: accounts[0] });

      console.log('Game started successfully!');
      resetGame();
    } catch (err) {
      console.error('Error in startGame:', err);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------
  // Single Return
  // ---------------------
  return (
    <div className="memory-match">
      <h1>Taxonomy Memory Match</h1>

      {/* Wallet Controls */}
      <div className="wallet-buttons">
        {!web3 ? (
          <button onClick={connectToMetaMask} disabled={loading}>
            {loading ? 'Connecting...' : 'Connect Wallet'}
          </button>
        ) : (
          <button onClick={disconnectWallet}>Disconnect Wallet</button>
        )}
      </div>

      {/* Game Info */}
      <div className="game-info">
        <p>Score: {score}</p>
        <p>Time Left: {timer}s</p>
      </div>

      {/* Cards */}
      <div className="memory-grid">
        {cards.map((image, index) => (
          <div
            key={index}
            className={
              'memory-card ' +
              (flipped.includes(index) || matched.includes(index)
                ? 'flipped'
                : '')
            }
            onClick={() => handleCardClick(index)}
          >
            {flipped.includes(index) || matched.includes(index) ? (
              <img src={image} alt="Card" />
            ) : (
              <div className="card-back"></div>
            )}
          </div>
        ))}
      </div>

      {/* Game Over */}
      {gameOver && (
        <div className="game-over">
          {timeRanOut ? (
            <>
              <h2>Are you ready!</h2>
              <p>Your Final Score: {score}</p>
            </>
          ) : (
            <>
              <h2>Congratulations!</h2>
              <p>Your Final Score: {score}</p>
            </>
          )}
        </div>
      )}

      {/* Start Game Button */}
      <div className="start-game">
        <button
          onClick={startGame}
          disabled={loading}
          style={{
            backgroundColor: 'blue',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          {loading ? 'Processing...' : 'Start Game'}
        </button>
      </div>
    </div>
  );
};

export default MemoryMatch;
