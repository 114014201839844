import { useState } from "react";
import { ethers } from "ethers";

// Contract Addresses
const VITAE_TOKEN_ADDRESS = "0x3c4bc6AF04AA092cdA5c7B239ee3EfC729a5b9C8";
const LIQUIDITY_POOL_ADDRESS = "0xD6D380E376909276556244c15F68159541A6bb05";

// ABIs
const VITAE_ABI = [
  "function approve(address spender, uint256 amount) public returns (bool)",
  "function allowance(address owner, address spender) public view returns (uint256)",
];
const LIQUIDITY_POOL_ABI = [
  "function addLiquidity(uint256 amount) public",
  "function removeLiquidity(uint256 amount) public",
  "function getLiquidity(address user) public view returns (uint256)",
  "function totalLiquidity() public view returns (uint256)",
];

function App() {
  const [walletConnected, setWalletConnected] = useState(false);
  const [account, setAccount] = useState("");
  const [amount, setAmount] = useState(""); // User input for token amount
  const [userLiquidity, setUserLiquidity] = useState("0"); // User's current liquidity

  // Connect Wallet
  const connectWallet = async () => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.send("eth_requestAccounts", []);
      setAccount(accounts[0]);
      setWalletConnected(true);

      // Fetch user's liquidity
      await fetchUserLiquidity(accounts[0]);
    } else {
      alert("MetaMask not detected");
    }
  };

  // Disconnect Wallet
  const disconnectWallet = () => {
    setWalletConnected(false);
    setAccount("");
    setUserLiquidity("0");
  };

  // Fetch User Liquidity
  const fetchUserLiquidity = async (userAddress) => {
    if (!walletConnected) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const liquidityPool = new ethers.Contract(LIQUIDITY_POOL_ADDRESS, LIQUIDITY_POOL_ABI, provider);

    const liquidity = await liquidityPool.getLiquidity(userAddress);
    setUserLiquidity(ethers.utils.formatUnits(liquidity, 18)); // Convert to human-readable format
  };

  // Approve VitaeToken
  const approveVitae = async () => {
    if (!walletConnected) return alert("Connect your wallet first!");

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const vitaeToken = new ethers.Contract(VITAE_TOKEN_ADDRESS, VITAE_ABI, signer);
    const liquidityPool = LIQUIDITY_POOL_ADDRESS;

    const parsedAmount = ethers.utils.parseUnits(amount, 18);

    const tx = await vitaeToken.approve(liquidityPool, parsedAmount);
    await tx.wait();
    alert("Tokens approved successfully!");
  };

  // Add Liquidity
  const addLiquidity = async () => {
    if (!walletConnected) return alert("Connect your wallet first!");

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const liquidityPool = new ethers.Contract(LIQUIDITY_POOL_ADDRESS, LIQUIDITY_POOL_ABI, signer);
    const parsedAmount = ethers.utils.parseUnits(amount, 18);

    const tx = await liquidityPool.addLiquidity(parsedAmount);
    await tx.wait();

    alert("Liquidity added successfully!");
    await fetchUserLiquidity(account); // Update user's liquidity
  };

  // Remove Liquidity
  const removeLiquidity = async () => {
    if (!walletConnected) return alert("Connect your wallet first!");

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const liquidityPool = new ethers.Contract(LIQUIDITY_POOL_ADDRESS, LIQUIDITY_POOL_ABI, signer);
    const parsedAmount = ethers.utils.parseUnits(amount, 18);

    const tx = await liquidityPool.removeLiquidity(parsedAmount);
    await tx.wait();

    alert("Liquidity removed successfully!");
    await fetchUserLiquidity(account); // Update user's liquidity
  };

  return (
    <div>
      <h1>Liquidity Pool Integration</h1>
      {!walletConnected ? (
        <button onClick={connectWallet}>Connect Wallet</button>
      ) : (
        <div>
          <p>Connected Account: {account}</p>
          <p>Your Current Liquidity: {userLiquidity} VTAE</p>
          <input
            type="number"
            placeholder="Enter amount of VTAE"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <button onClick={approveVitae}>Approve Tokens</button>
          <button onClick={addLiquidity}>Add Liquidity</button>
          <button onClick={removeLiquidity}>Remove Liquidity</button>
          <button onClick={disconnectWallet}>Disconnect Wallet</button>
        </div>
      )}
    </div>
  );
}

export default App;
