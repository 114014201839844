// MushroomTaxonomy.js
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import Web3 from 'web3';
import contractABI from './contractABI'; // Import the ABI
import Footer from './Footer'; // Make sure the path is correct
import './MushroomTaxonomy.css'; // Import the CSS file

// NftCard Component
const NftCard = ({ nft, mintNFT, mintCounts }) => (
  <Col key={nft.id} md={4}>
    <Card className="mb-4">
      <Card.Img variant="top" src={nft.image} alt={nft.name} />
      {nft.id === 1 && (
        <>
          <img 
            src="https://bafybeiedchoj5woo4hgywxynrraf256wuj5oqxjasdk6jc7obkhemoeshq.ipfs.nftstorage.link/" 
            alt="Ganoderma Lucidum GIF"
            style={{ width: '10%', height: 'auto' }}  
          />
        </>  
      )}
      <Card.Body>
        <Card.Title style={{ color: 'black' }}>{nft.name}</Card.Title>
        <Card.Text style={{ color: 'black' }}>{nft.description}</Card.Text>
        <p style={{ color: 'black' }}>Minted: {mintCounts[nft.image] || 0} times</p>
        <Button variant="primary" onClick={() => mintNFT(nft.metadata)}>
          Purchase NFT
        </Button>
      </Card.Body>
    </Card>
  </Col>
);



// LoadingIndicator Component
const LoadingIndicator = () => (
  <div className="text-center">
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
    <p>Loading...</p>
  </div>
);


const contractAddress = '0xbe6Cb2Ea1C78848e6FBE06A152AdF906D5c4706E'; // Your contract address

const NFTs = [
  {
    id: 1,
    name: 'Ganoderma Lucidum',
    description: `Ganoderma lucidum: A Mystical Healer from Nature's Treasury

In the realm of natural wonders, Ganoderma lucidum, also revered as the "Lingzhi" or "Reishi" mushroom, emerges as a symbol of longevity and health. This remarkable fungus, with its glossy, varnished appearance, is not just a feast for the eyes but a powerhouse of medicinal virtues, revered in traditional medicine for centuries.

Habitat: A Forest's Gift
Nestled in the dense, humid woodlands across Asia, Ganoderma lucidum thrives on the hardwood of decaying trees. It's a sight to behold, often found perched like a wooden crown, signaling the presence of ancient wisdom in the forest's underbelly.

Identification: The Hallmark of Healing
Identifying this mushroom is like uncovering a hidden gem. It boasts a distinctive, kidney-shaped cap, ranging from deep red to black. The underside reveals tiny pores instead of the common gills, a unique trait that sets it apart in the fungal kingdom.

Medicinal Benefits: Nature's Healing Touch
The real magic of Ganoderma lucidum lies in its medicinal prowess. Historically dubbed the "mushroom of immortality," it's a treasure trove of health benefits. Modern research corroborates its role in boosting the immune system, potentially offering a shield against numerous ailments. It's a natural ally in promoting heart health, balancing blood sugar levels, and may even have properties that fight against cancerous cells.

Chemical Constituents: The Alchemy of Wellness
The secret to its healing capabilities lies in its rich composition. Packed with bioactive molecules such as polysaccharides, triterpenoids, and peptidoglycans, Ganoderma lucidum is a laboratory of natural compounds. These constituents are thought to be the keystones behind its anti-inflammatory, antioxidative, and anti-tumoral properties, making it a subject of intense study in pharmacology.

In the world of natural healing and wellness, Ganoderma lucidum stands out as a beacon of hope and an emblem of the symbiotic relationship we share with nature's intricate web of life.

Ganoderma lucidum, is traditionally used in various forms for its medicinal properties. One such form is "stick form" or "sliced form," where the mushroom is dried and cut into long, thin slices or sticks. These sticks are typically used for making herbal teas or decoctions.

In this form, the hard, woody texture of the dried Ganoderma lucidum becomes more manageable, allowing for easier extraction of its beneficial compounds during the brewing process. The stick form is convenient for dosage measurement and storage, and it is a popular way to consume the mushroom in traditional Chinese medicine.

To prepare a tea or decoction from Ganoderma lucidum sticks, they are usually simmered in water for an extended period, often for several hours, to extract the maximum amount of active ingredients. The resulting liquid can be consumed directly or used as a base for other preparations.

This traditional method of preparation and consumption harnesses the mushroom's potential benefits, including immune system support, anti-inflammatory properties, and overall wellness enhancement. However, it's important to source these mushrooms from reputable suppliers to ensure quality and safety.`,
    image: 'https://bafkreidpdakzuy6tw4rfknjox3xktjyqrxhhzsimfwekzxxnm5wjwirx3i.ipfs.nftstorage.link/',
    metadata: 'https://bafkreigf3qtb3kvblfihpo65od7ldn5kg7zw26qqkrmaan6unefdxbvfmu.ipfs.nftstorage.link/',
    },
    
  ];

function App() {
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mintCounts, setMintCounts] = useState({});

   const updateMintCounts = useCallback(async () => {
    if (!contract) return;
    const newMintCounts = {};
    for (const nft of NFTs) {
      const mintCount = await contract.methods.getMintCount(nft.image).call();
      newMintCounts[nft.image] = mintCount;
    }
    setMintCounts(newMintCounts);
  }, [contract]); 





useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        try {
          setLoading(true);
          const newWeb3 = new Web3(window.ethereum);
          await window.eth_requestAccounts;
          const accounts = await newWeb3.eth.getAccounts();
          setWeb3(newWeb3);
          setAccounts(accounts);

          const newContract = new newWeb3.eth.Contract(contractABI, contractAddress);
          setContract(newContract);
        } catch (error) {
          console.error("Error initializing web3:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    initWeb3();
  }, []);

  useEffect(() => {
    if (contract) {
      updateMintCounts();
    }
  }, [contract, updateMintCounts]); // Add 'contract' as a dependency


  const mintNFT = async (imageUri) => {
  try {
    const mintValue = web3.utils.toWei("0.001", "ether"); // Convert 0.001 ETH to Wei
    await contract.methods.safeMint(accounts[0], imageUri).send({ from: accounts[0], value: mintValue });
    const newMintCount = await contract.methods.getMintCount(imageUri).call();
    const newMintCounts = { ...mintCounts, [imageUri]: newMintCount };
    setMintCounts(newMintCounts);
    console.log("Updated mintCounts:", newMintCounts);
  } catch (error) {
    console.error("Error minting NFT:", error);
  }
};
  // Rest of your App component code...
  // Including rendering of NFTs and other UI elements
  // ...

  const connectToMetaMask = async () => {
    try {
      setLoading(true);
      if (window.ethereum) {
        const newWeb3 = new Web3(window.ethereum);
        await window.eth_requestAccounts;
        const accounts = await newWeb3.eth.getAccounts();
        setWeb3(newWeb3);
        setAccounts(accounts);
      } else {
        console.error('MetaMask not found');
      }
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    } finally {
      setLoading(false);
    }
  };

  const disconnectWallet = () => {
    setWeb3(null);
    setAccounts([]);
  };

  const backgroundUrl = 'https://bafybeiaotikvw5r42m34zbevk2mgrlkweptyhu3wyorfjymwy2zm4ky2zy.ipfs.nftstorage.link/';

  return (
    <div>
      <div style={{ backgroundImage: `url(${backgroundUrl})`, backgroundSize: 'cover', minHeight: '100vh' }}>
        <Container>
          <h1 className="mt-4 mb-4 text-center">Mushroom Taxonomy NFT Minting</h1>


        {!web3 && !loading && (
          <div className="text-center">
            <p>Not connected to Wallet</p>
            <Button variant="primary" onClick={connectToMetaMask}>
              Connect to Wallet
            </Button>
          </div>
        )}

        {web3 && accounts.length > 0 && (
          <div className="text-center">
            <p>Connected Account: {accounts[0]}</p>
            <Button variant="danger" onClick={disconnectWallet}>
              Disconnect Wallet
            </Button>
          </div>
        )}

        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Row>
                {NFTs.map((nft) => (
                  <NftCard key={nft.id} nft={nft} mintNFT={mintNFT} mintCounts={mintCounts} />
                ))}
              </Row>
                          
              <h2 className="mt-5 text-center">Mushrooms Coming soon!</h2>
              <Row className="mushroom-grid">
                {[
                  'https://harlequin-improved-quokka-58.mypinata.cloud/ipfs/bafybeidqtvs6ftm4vjgq33xrii3zdp34kk2cpguujgdtzs5gy5kixhcx2u',
                  'https://harlequin-improved-quokka-58.mypinata.cloud/ipfs/bafybeic2e6lpcuoi3gyguvnkqtgozigoy7hk7hgj6aux62hazydtce6hn4',
                  'https://harlequin-improved-quokka-58.mypinata.cloud/ipfs/bafybeihu3u32jrdtaj4bxlwo3ft6py6a4u6awqdsis52x3b5msetxniklm',
                  'https://harlequin-improved-quokka-58.mypinata.cloud/ipfs/bafybeihxs3z4ecmz6uasz6bkmk7wfkeyqdsgfp5f3ht4y2f725mpq2do7y',
                  'https://harlequin-improved-quokka-58.mypinata.cloud/ipfs/bafybeihy57nlelg2pfctsdctg3zgimpe4ekkdqasm3unsx7kj4rhjkhrhy',
                  'https://harlequin-improved-quokka-58.mypinata.cloud/ipfs/bafybeidzegl2aly5jjdabtctwet7snqbdjc42bqsefhrssi5fxa3epd5ay',
                  'https://harlequin-improved-quokka-58.mypinata.cloud/ipfs/bafybeic7htsvxtpovejncryqyc6hcui54bwktzha7evbr5qzh6m4f6dnn4',
                  'https://harlequin-improved-quokka-58.mypinata.cloud/ipfs/bafybeidx72qa7rao6zxq4taw43t4lfovrxkehjb556b7clw7e6akkqkvta',
                  'https://harlequin-improved-quokka-58.mypinata.cloud/ipfs/bafybeierxugwnhtp6hbob533cssmfmle5qxunlpgzi5lllkwkasxyjqk3q',
                  'https://harlequin-improved-quokka-58.mypinata.cloud/ipfs/bafybeicxt4amb757qpbzqq2n7yrkreu335myva72qwm7lypgsjvmmb4ypu',
                ].map((imageUrl, index) => (
                  <Col key={index} md={4} className="mushroom-card">
                    <img src={imageUrl} alt={`Mushroom ${index + 1}`} />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default App;
