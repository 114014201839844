import React from 'react';

const KromNFTWhitePaper = () => {
  return (
    <div className="white-paper">
      <h1>KromNFT (KNFT) and Vitae (VTAE) White Paper</h1>

      <section>
        <h2>Abstract</h2>
        <p>
          KromNFT (KNFT) and Vitae (VTAE) form a unified blockchain ecosystem designed to revolutionize education, sustainability, and conservation. By leveraging Ethereum blockchain standards, KNFT empowers users to mint and trade unique NFTs representing plant and fungi species, while VTAE functions as the ecosystem's utility token, facilitating transactions and engaging experiences like the Taxonomy Memory Match Game. Together, these tokens create a decentralized platform that fosters environmental awareness, digital ownership, and gamified learning.
        </p>
      </section>

      <section>
        <h2>Introduction</h2>
        <h3>Mission</h3>
        <p>
          Our mission is to harness blockchain technology to advance education and environmental conservation. By integrating KNFT and VTAE, we aim to:
        </p>
        <ul>
          <li>Educate users about plant and fungi species.</li>
          <li>Promote conservation through digital and real-world initiatives.</li>
          <li>Provide an engaging, decentralized platform for learning and interaction.</li>
        </ul>

        <h3>The Ecosystem</h3>
        <ul>
          <li><strong>KromNFT (KNFT):</strong> Represents unique NFTs tied to plant and fungi species, each paired with ecological and scientific insights.</li>
          <li><strong>Vitae (VTAE):</strong> Powers the ecosystem, enabling transactions, gameplay, and community-driven initiatives.</li>
        </ul>
      </section>

      <section>
        <h2>Token Overview</h2>
        <h3>KromNFT (KNFT)</h3>
        <p>
          KNFT is an ERC-721 token standard for non-fungible tokens, enabling users to:
        </p>
        <ul>
          <li>Mint NFTs representing specific plant or fungi species.</li>
          <li>Own digital assets tied to ecological data and expert insights.</li>
          <li>Trade NFTs on TaxonomyNFTs.com or other marketplaces.</li>
          <li>Purchase KNFT NFTs using ETH, the native currency of the Ethereum blockchain.</li>
        </ul>

        <h3>Vitae (VTAE)</h3>
        <p>
          VTAE is an ERC-20 utility token designed to:
        </p>
        <ul>
          <li>Facilitate transactions within the ecosystem.</li>
          <li>Enable participation in games like the Taxonomy Memory Match Game.</li>
          <li>Support sustainability projects and conservation efforts.</li>
        </ul>
      </section>

      <section>
        <h2>Ecosystem Integration</h2>
        <ul>
          <li><strong>NFT Minting:</strong> Users spend ETH to mint KNFT tokens, gaining ownership of unique digital assets.</li>
          <li><strong>NFT Purchases:</strong> Users can purchase KNFT NFTs using ETH, further integrating the Ethereum blockchain into the ecosystem.</li>
          <li><strong>Gaming:</strong> VTAE is required to play the Taxonomy Memory Match Game, rewarding players with ecological insights and engagement.</li>
          <li><strong>Conservation:</strong> A portion of VTAE transactions contributes to funding biodiversity and sustainability research.</li>
        </ul>
      </section>

      <section>
        <h2>Tokenomics</h2>
        <h3>KNFT</h3>
        <ul>
          <li><strong>Supply:</strong> Unlimited, as each KNFT corresponds to a unique plant or fungi species.</li>
          <li><strong>Utility:</strong> Educational content, ecological preservation, and trading.</li>
        </ul>

        <h3>VTAE</h3>
        <p><strong>Total Supply:</strong> 1,000,000,000 VTAE</p>
        
        <h3>Liquidity Pooling</h3>
        <p>
          We are implementing liquidity pools on decentralized exchanges (DEXs). Liquidity pools will:
        </p>
        <ul>
          <li>https://app.uniswap.org/explore/pools/ethereum/0x1D21872E48268745f5E85A8e7389b74779602d7F </li> 
          <li>Acquire VTAE on Uniswap to play the Taxonomy Memory Match game, or contribute VTAE to the liquidity pool on TaxonomyNFTs to become eligible for random VTAE airdrops!</li>
          <li>Enhance Trading: Provide liquidity for seamless VTAE transactions.</li>  
          <li>Stabilize Value: Reduce volatility and ensure fair pricing.</li>
          <li>Build Trust: Demonstrate ecosystem reliability and commitment to a robust token economy.</li>
        </ul>
      </section>

      <section>
        <h2>Use Cases</h2>
        <h3>KNFT</h3>
        <ul>
          <li><strong>Plant and Fungi Identification:</strong> Each NFT provides detailed information about species, ecological significance, and potential uses.</li>
          <li><strong>Collectibility:</strong> Users can curate unique digital collections.</li>
          <li><strong>Trading:</strong> KNFTs are tradable on TaxonomyNFTs.com and compatible marketplaces.</li>
          <li><strong>Purchases:</strong> Users can buy KNFT NFTs directly using ETH.</li>
        </ul>

        <h3>VTAE</h3>
        <ul>
          <li><strong>Gameplay:</strong> Players use VTAE to access the Taxonomy Memory Match Game, promoting learning and engagement.</li>
          <li><strong>Transactions:</strong> VTAE facilitates NFT minting, marketplace trading, and other ecosystem activities.</li>
          <li><strong>Sustainability Support:</strong> Proceeds from VTAE transactions fund conservation efforts.</li>
        </ul>
      </section>

      <section>
        <h2>Technical Overview</h2>
        <h3>Standards</h3>
        <ul>
          <li><strong>KNFT:</strong> ERC-721, providing unique, non-fungible digital assets.</li>
          <li><strong>VTAE:</strong> ERC-20, ensuring secure and efficient transactions.</li>
        </ul>
        <h3>Security</h3>
        <p>
          Smart contracts are rigorously audited to ensure integrity and safety. Adherence to Ethereum’s Proof-of-Stake mechanism reduces environmental impact.
        </p>
      </section>

      <section>
        <h2>Roadmap</h2>
        <ul>
          <li><strong>2025 Q1:</strong> Launch Taxonomy Memory Match Game.</li>
          <li><strong>2025 Q2:</strong> Expand NFT collections with additional plant and fungi species.</li>          
        </ul>
      </section>

      <section>
        <h2>Regulatory Compliance</h2>
        <p>
          We are committed to adhering to all relevant legal and regulatory frameworks, ensuring compliance with AML and KYC policies to maintain secure and ethical transactions.
        </p>
      </section>

      <section>
        <h2>Conclusion</h2>
        <p>
          KromNFT (KNFT) and Vitae (VTAE) together form a groundbreaking ecosystem that blends education, sustainability, and blockchain innovation. By empowering users with tools to learn, trade, and contribute to conservation, we envision a future where technology and nature coexist harmoniously. Join us in shaping this future.
        </p>
      </section>

      <section>
        <h2>Contact and Community</h2>
        <p><strong>Website:</strong> <a href="http://TaxonomyNFTs.com" target="_blank" rel="noopener noreferrer">TaxonomyNFTs.com</a></p>
        <p><strong>Twitter (X):</strong> <a href="https://twitter.com/CKromke" target="_blank" rel="noopener noreferrer">@CKromke</a></p>
        <p><strong>Email:</strong> info@taxonomynfts.com</p>
        <p><strong>Discord:</strong> <a href="https://discord.gg/JCh8pr9g" target="_blank" rel="noopener noreferrer">Join Our Community</a></p>
      </section>
    </div>
  );
};

export default KromNFTWhitePaper;
